import React, { useEffect, useState }from 'react';
import { Result, Spin, Collapse, Modal, Tabs, Button, Typography, Tree } from 'antd';
import { Comment } from '@ant-design/compatible';
import { SearchOutlined } from '@ant-design/icons';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Text } = Typography;

const recursiveClsIds = (response, array) => {
    for(var i=0; i<response.length; ++i) {
        var subs = response[i].subs;
        if(!!subs) {
            recursiveClsIds(subs, array);
        } else {
            array.push(response[i].classId)
        }
    }
}

export const AreaModal_Edit = ({modalOpen, modalControl, setAreaTEvalue, setAreaCLvalue, areaTEvalue, areaCLvalue, setFieldValue}) => {

    const [areaNoParents, setAreaNoParents] = useState([]);
    const [areaNoParents2, setAreaNoParents2] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [areaList2, setAreaList2] = useState([]);
   
    useEffect(() => {
        // 領域-12年國教
        DuckIO.doGet(AppConfig.CLS_ALL('TWELVE_EDU'), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLS_ALL TWELVE_EDU resultCode' + result.resultCode);
            } else {
                if(!!result.response ) {
                    var array = [];
                    // 取出領域母層過濾剩下領域子層 (母層全選時不顯示名稱)
                    recursiveClsIds(result.response, array); 
                    setAreaNoParents(array);

                    setAreaList(result.response);
                } else {
                    setAreaList([]);
                }
            }
        }); 

        // 領域-大專校院
        DuckIO.doGet(AppConfig.CLS_ALL('COLLEGE'), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLS_ALL COLLEGE resultCode' + result.resultCode);
            } else {
                if(!!result.response ) {
                    var array = [];
                    recursiveClsIds(result.response, array);
                    setAreaNoParents2(array);

                    setAreaList2(result.response);
                } else {
                    setAreaList2([]);
                }
            }
        }); 

        
        
    }, []);

    const onChange = () => {
        console.log('onChange');
        //setMopen(false);
    };

    const AreaHandleCancel = () => {
        console.log('AreaHandleCancel');
        modalControl(false);
    };

    const AreaHandleOk = () => {
        modalControl(false);
    };

    const AreaOnCheck = (checkedKeys, info, type) => {
        let a1 = areaTEvalue;
        let b1 = areaCLvalue;

        if (type === 'TWELVE_EDU') {
            // 該Tree 目前選取所有節點 過濾掉母層
            let a = info.checkedNodes.filter(x=>{
                return  areaNoParents.includes(x.classId);
            });
            let d = a.map((x)=> {return {label:x.className,value:x.classId}});
            setAreaTEvalue(d); // 將最新值更新
            setFieldValue("classId",d.concat(b1)); // 將最新陣列與其他類別合併
         
        } else if (type === 'COLLEGE') {
            // 已目前選取所有節點 過濾掉母層
            let b = info.checkedNodes.filter(x=>{
                return  areaNoParents2.includes(x.classId);
            });
            let c = b.map((x)=> {return {label:x.className,value:x.classId}});
            setAreaCLvalue(c);
            setFieldValue("classId",c.concat(a1));

        } else {
            console.log('else')
        }
        
    };

    return (
        <Modal 
            title="選擇專長領域" 
            open={modalOpen} 
            closable={false}
            onCancel={AreaHandleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={AreaHandleOk}>完成</Button>
            ]}
            destroyOnClose={true} //select 選項刪除更新時卸載 tree 已選擇的會再重build 
        >
            <Text type="danger" >點擊<CaretRightOutlined />可展開領域細項，可使用滑鼠滾輪往下查看更多領域</Text>
            <Tabs 
                defaultActiveKey="1" 
                items={[{
                        key: '1',
                        label: '十二年國教',
                        children: 
                        <div style={{paddingTop:'10px'}}>
                            <Tree
                                checkable
                                fieldNames={{title: 'className' , key: 'classId', children: 'subs'}}
                                onCheck={(checkedKeys, info, type='TWELVE_EDU')=>{
                                    AreaOnCheck(checkedKeys, info, type);
                                }}
                                treeData={areaList}
                                defaultSelectedKeys={areaTEvalue.map(x=>x.value)}
                                defaultCheckedKeys={areaTEvalue.map(x=>x.value)}
                            />
                        </div>,
                    },
                    {
                        key: '2',
                        label: '大專校院',
                        children: 
                        <div style={{paddingTop:'10px'}}>
                            <Tree
                                checkable
                                fieldNames={{title: 'className' , key: 'classId', children: 'subs'}}
                                onCheck={(checkedKeys, info, type='COLLEGE')=>{
                                    AreaOnCheck(checkedKeys, info, type);
                                }}
                                treeData={areaList2}
                                defaultSelectedKeys={areaCLvalue.map(x=>x.value)}
                                defaultCheckedKeys={areaCLvalue.map(x=>x.value)}
                            />
                        </div>,
                    }]} 
                onChange={onChange} />
        </Modal>
)};

export const AreaModal_SEARCH = ({modalOpen, modalControl, setSelectObj, setAreaTEvalue,setAreaCLvalue, areaTEvalue, areaCLvalue}) => {

    const [areaParents, setAreaParents] = useState([]);
    const [areaParents2, setAreaParents2] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [areaList2, setAreaList2] = useState([]);
   
    useEffect(() => {
        // 領域-12年國教
        DuckIO.doGet(AppConfig.CLS_ALL('TWELVE_EDU'), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLS_ALL TWELVE_EDU resultCode' + result.resultCode);
            } else {
                if(!!result.response ) {
                    var array = [];
                    // 取出領域母層過濾剩下領域子層 (母層全選時不顯示名稱)
                    recursiveClsIds(result.response, array); 
                    setAreaParents(array);

                    setAreaList(result.response);
                } else {
                    setAreaList([]);
                }
            }
        }); 

        // 領域-大專校院
        DuckIO.doGet(AppConfig.CLS_ALL('COLLEGE'), null, (result) => {
            if (result.resultCode !== 10) {
                alert('CLS_ALL COLLEGE resultCode' + result.resultCode);
            } else {
                if(!!result.response ) {
                    var array = [];
                    recursiveClsIds(result.response, array);
                    setAreaParents2(array);

                    setAreaList2(result.response);
                } else {
                    setAreaList2([]);
                }
            }
        }); 

        
        
    }, []);

    const onChange = () => {
        // Tab切換
    };

    const AreaHandleCancel = () => {
        modalControl(false);
    };

    const AreaHandleOk = () => {
        modalControl(false);
    };

    const AreaOnCheck = (checkedKeys, info, type) => {
        let a1 = areaTEvalue;
        let b1 = areaCLvalue; 

        if (type === 'TWELVE_EDU') {
            // 該Tree所有選擇的 過濾母層
             let a = info.checkedNodes.filter(x=>{
                 return  areaParents.includes(x.classId);
            });
            // 轉key value 格式
             a = a.map((x)=> {return {label:x.className, value:x.classId, type: x.levelType}});
             setAreaTEvalue(a);
             setSelectObj(a.concat(b1));
        } else if (type === 'COLLEGE') {
           
             let b = info.checkedNodes.filter(x=>{
                 return  areaParents2.includes(x.classId);
             });
             b = b.map((x)=> {return {label:x.className, value:x.classId, type: x.levelType}});
             setAreaCLvalue(b);
             setSelectObj(b.concat(a1));
        } else {
             console.log('else')
        };

    };

    return (
        <Modal 
            title="選擇專長領域" 
            open={modalOpen} 
            closable={false}
            onCancel={AreaHandleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={AreaHandleOk}>完成</Button>
            ]}
            destroyOnClose={true} //select 選項刪除更新時卸載 tree 已選擇的會再重build 
        >
            <Text type="danger" >點擊<CaretRightOutlined />可展開領域細項，可使用滑鼠滾輪往下查看更多領域</Text>
            <Tabs 
                defaultActiveKey="1" 
                items={[{
                        key: '1',
                        label: '十二年國教',
                        children: 
                        <div style={{paddingTop:'10px'}}>
                            <Tree
                                checkable
                                fieldNames={{title: 'className' , key: 'classId', children: 'subs'}}
                                onCheck={(checkedKeys, info, type='TWELVE_EDU')=>{
                                    AreaOnCheck(checkedKeys, info, type);
                                }}
                                treeData={areaList}
                                defaultSelectedKeys={areaTEvalue.map(x=>x.value)}
                                defaultCheckedKeys={areaTEvalue.map(x=>x.value)}
                            />
                        </div>,
                    },
                    {
                        key: '2',
                        label: '大專校院',
                        children: 
                        <div style={{paddingTop:'10px'}}>
                            <Tree
                                checkable
                                fieldNames={{title: 'className' , key: 'classId', children: 'subs'}}
                                onCheck={(checkedKeys, info, type='COLLEGE')=>{
                                    AreaOnCheck(checkedKeys, info, type);
                                }}
                                treeData={areaList2}
                                defaultSelectedKeys={areaCLvalue.map(x=>x.value)}
                                defaultCheckedKeys={areaCLvalue.map(x=>x.value)}
                            />
                        </div>,
                    }]} 
                onChange={onChange} />
        </Modal>
)};

export const Loading = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin size="large" />
        </div>
    );
};

export const NotFound = (props) => {
    return <Result icon={<SearchOutlined style={{ color: 'cadetblue' }} />} title={props.title} />;
};

export const R_Comment = ({...props}) => {
    return <Comment 
                actions={props.actions}
                author={`${props.data.name}`}
                content={props.data.message}
                datetime={props.data.createAt}
                key={props.data.replyFromNum}
            >   
            {!!props.data.replyList && props.data.replyList.length > 0 &&
                <Collapse bordered={false} >
                    <Panel header={`其他${props.data.replyList.length}則回覆`} key="1">
                    {props.data.replyList.sort((a,b)=> new Date(b.createAt) - new Date(a.createAt)).map((x,i)=> {
                        return <Comment
                                style={{margin: '0px'}}
                                author={`${x.name || ''}`}
                                content={x.message || ''}
                                datetime={x.createAt || ''}
                                key={i}
                                />
                    })} 
                    </Panel>
                </Collapse>
            }
            </Comment>
    ;
};
